import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: Home
    component: () => import('../pages/Home.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/end-point',
    name: 'End-Point',
    component: () => import('@/pages/end-point/EndPointPage.vue')
  },
  {
    path: '/end-point/:id',
    name: 'End-Point Detail',
    component: () => import('@/pages/end-point/EndPointDetailPage.vue')
  },
  {
    path: '/end-point/:id/edit',
    name: 'End-Point Edit',
    component: () => import('@/pages/end-point/EndPointEditPage.vue')
  },
  {
    path: '/mqtt',
    name: 'MQTT',
    component: () => import('@/pages/mqtt/MQTTPage.vue'),
    children: [
      {
        path: 'connections',
        name: 'MQTT Connections',
        component: () => import('@/pages/mqtt/MQTTConnectionsPage.vue')
      },
      {
        path: 'subscriptions',
        name: 'MQTT Subscriptions',
        component: () => import('@/pages/mqtt/MQTTSubscriptionsPage.vue')
      }
    ]
  },
  {
    path: '/fiware',
    name: 'Fiware',
    redirect: '/fiware/services',
    component: () => import('@/pages/fiware/FiwarePage.vue'),
    children: [
      {
        path: 'services',
        name: 'Fiware Service',
        component: () => import('@/pages/fiware/FiwareServicePage.vue')
      },
      {
        path: 'entities',
        name: 'Fiware Entity',
        component: () => import('@/pages/fiware/FiwareEntityPage.vue'),
        props: route => ({ query: route.query.page })
      },
      {
        path: 'entities/:id',
        name: 'Fiware Entity Detail',
        component: () => import('@/pages/fiware/FiwareEntityDetailPage.vue')
      },
      {
        path: 'subscriptions',
        name: 'Fiware Subscriptions',
        component: () => import('@/pages/fiware/FiwareSubscribePage.vue')
      },
      {
        path: 'subscriptions/:id',
        name: 'Fiware Subscriptions Detail',
        component: () => import('@/pages/fiware/FiwareSubscribeDetailPage.vue')
      },
      {
        path: 'registration',
        name: 'Fiware Registration',
        component: () => import('@/pages/fiware/FiwareRegistrationPage.vue')
      },
      {
        path: 'registration/:id',
        name: 'Fiware Registration Detail',
        component: () => import('@/pages/fiware/FiwareRegistrationDetailPage.vue')
      }
    ]
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('@/components/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log(to, from)
//   console.log(JSON.parse(localStorage.getItem('isSignin')))
//   if (!JSON.parse(localStorage.getItem('isSignin'))) {
//     location.reload()
//   }
//   next()
// })

export default router
