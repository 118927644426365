<template>
  <div>
    <h3>{{ title }}</h3>
    <span>{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  components: {
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
