// let baseURL = 'https://portal.meca.in.th'
// let baseURL = 'https://living.data-platform.web.meca.in.th'
// let baseURL = 'https://mep-rs.data-platform.web.meca.in.th/'
let baseURL = 'https://mqtt-api.data-platform.web.meca.in.th'
// let baseURL = 'http://localhost:8000'
// let baseURL = 'http://localhost:3000'
let OrionURL = 'http://localhost:1026'
let agentURL = 'http://localhost:4041'
let esbaseURL = 'http://localhost:9200'
let urlKc = 'http://localhost:8090/auth'

export default {

  get (endpoint, service = null, servicePath = null, token = null) {
    let headers = {
      // 'Accept': 'application/json',
      // 'Content-Type': 'application/json',
      'fiware-service': service
    }
    if (servicePath) {
      headers['fiware-servicepath'] = servicePath
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    return fetch(baseURL + endpoint, { method: 'GET', headers })
  },
  post (endpoint, body = {}, service = null, servicePath = null, token = null) {
    let headers = {
      'Content-Type': 'application/json',
      'fiware-service': service,
      'fiware-servicepath': servicePath
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    return fetch(baseURL + endpoint, { method: 'POST', headers, body: JSON.stringify(body) })
  },
  put (endpoint, body = {}, service = null, servicePath = null, token = null) {
    let headers = {
      // 'Accept': 'application/json',
      'Content-Type': 'application/json',
      'fiware-service': service
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    if (servicePath) {
      headers['fiware-servicepath'] = servicePath
    }
    return fetch(baseURL + endpoint, { method: 'PUT', headers, body: JSON.stringify(body) })
  },
  delete (endpoint, service = null, servicePath = null, token = null) {
    let headers = {
      // 'Accept': 'application/json',
      'Content-Type': 'application/json',
      'fiware-service': service,
      'fiware-servicepath': servicePath
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    return fetch(baseURL + endpoint, { method: 'DELETE', headers })
  },
  postRPT (endpoint, body, token = null) {
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    return fetch(endpoint, { method: 'POST', headers, body: body })
  },
  patch (endpoint, body = {}, service = null, servicePath = null, token = null) {
    let headers = {
      // 'Accept': 'application/json',
      'Content-Type': 'application/json',
      'fiware-service': service,
      'fiware-servicepath': servicePath
    }
    if (token) {
      headers['Authorization'] = 'JWT ' + token
    }
    return fetch(OrionURL + endpoint, { method: 'PATCH', headers, body: JSON.stringify(body) })
  },
  esget (endpoint, token = null) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = 'JWT ' + token
    }
    return fetch(esbaseURL + endpoint, { method: 'GET', headers })
  },
  kcGET (endpoint, token = null) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    return fetch(urlKc + endpoint, { method: 'GET', headers })
  },
  kcput (endpoint, body = {}, token = null) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    return fetch(urlKc + endpoint, { method: 'PUT', headers, body: JSON.stringify(body) })
  },
  upload (endpoint, body = {}, token = null) {
    let formData = new window.FormData()
    for (let key in body) {
      formData.append(key, body[key])
    }
    let headers = {}
    if (token) {
      headers['Authorization'] = 'JWT ' + token
    }
    return fetch(baseURL + endpoint, { method: 'POST', headers, body: formData })
  },
  kcdelete (endpoint, token = null, body = null) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      // 'Accept': 'text/plain',
      // 'Content-Type': 'text/plain'
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    return fetch(urlKc + endpoint, { method: 'DELETE', headers, body: JSON.stringify(body) })
  },
  download (endpoint, token = null) {
    let headers = {}
    if (token) {
      headers['Authorization'] = 'JWT ' + token
    }
    return fetch(baseURL + endpoint, { method: 'GET', headers })
  },
  tracking (endpoint, licenseId = null, body = {}) {
    let headers = {
      'Content-Type': 'application/json'
    }
    if (licenseId) {
      headers['X-DRIVERCODE'] = licenseId
    }
    return fetch(baseURL + endpoint, { method: 'POST', headers, body: JSON.stringify(body) })
  },
  getYaml (endpoint, token = null) {
    let headers = {
      'Accept': 'application/yaml',
      'Content-Type': 'application/yaml'
    }
    if (token) {
      headers['Authorization'] = 'JWT ' + token
    }
    return fetch(baseURL + endpoint, { method: 'GET', headers })
  },
  entityGET (endpoint, service = null, servicePath = null, token = null) {
    let headers = {
      // 'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'application/json',
      'fiware-service': service,
      'fiware-servicepath': servicePath
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    return fetch(OrionURL + endpoint, { method: 'GET', headers })
  },
  entitiesPOST (endpoint, body = {}, service = null, servicePath = null, token = null) {
    let headers = {
      // 'Accept': 'application/json',
      'Content-Type': 'application/json',
      'fiware-service': service,
      'fiware-servicepath': servicePath
    }
    if (token) {
      headers['Authorization'] = 'JWT ' + token
    }
    return fetch(OrionURL + endpoint, { method: 'POST', headers, body: JSON.stringify(body) })
  },
  subscriptionGET (endpoint, service = null, servicePath = null, token = null) {
    let headers = {
      // 'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'application/json',
      'fiware-service': service,
      'fiware-servicepath': servicePath
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    return fetch(OrionURL + endpoint, { method: 'GET', headers })
  },
  subscriptionPOST (endpoint, body = {}, service = null, servicePath = null, token = null) {
    let headers = {
      // 'Accept': 'application/json',
      'Content-Type': 'application/json',
      'fiware-service': service,
      'fiware-servicepath': servicePath
    }
    if (token) {
      headers['Authorization'] = 'JWT ' + token
    }
    return fetch(OrionURL + endpoint, { method: 'POST', headers, body: JSON.stringify(body) })
  },
  servicesGET (endpoint, token = null) {
    let headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'application/json',
      'fiware-service': 'openiot',
      'fiware-servicepath': '/'
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    return fetch(agentURL + endpoint, { method: 'GET', headers })
  },
  registGET (endpoint, service = null, servicePath = null, token = null) {
    let headers = {
      // 'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'application/json',
      'fiware-service': service,
      'fiware-servicepath': servicePath
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    return fetch(OrionURL + endpoint, { method: 'GET', headers })
  },
  registPOST (endpoint, body = {}, token = null) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = 'JWT ' + token
    }
    return fetch(OrionURL + endpoint, { method: 'POST', headers, body: JSON.stringify(body) })
  },
  registDelete (endpoint, body = {}, token = null) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = 'JWT ' + token
    }
    return fetch(OrionURL + endpoint, { method: 'DELETE', headers, body: JSON.stringify(body) })
  },
  fwGET (endpoint, service = null, servicePath = null, token = null) {
    let headers = {
      // 'Content-Type': 'application/json',
      'fiware-service': service,
      'fiware-servicepath': servicePath
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    return fetch(OrionURL + endpoint, { method: 'GET', headers })
  },
  fwPUT (endpoint, body = {}, token = null) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = 'JWT ' + token
    }
    return fetch(baseURL + endpoint, { method: 'PUT', headers, body: JSON.stringify(body) })
  },
  fwPOST (endpoint, body = {}, service = null, servicePath = null, token = null) {
    let headers = {
      // 'Accept': 'application/json',
      'Content-Type': 'application/json',
      'fiware-service': service,
      'fiware-servicepath': servicePath
    }
    if (token) {
      headers['Authorization'] = 'JWT ' + token
    }
    return fetch(OrionURL + endpoint, { method: 'POST', headers, body: JSON.stringify(body) })
  },
  fwSubPOST (endpoint, body = {}, token = null) {
    let headers = {
      // 'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = 'JWT ' + token
    }
    return fetch(OrionURL + endpoint, { method: 'POST', headers, body: JSON.stringify(body) })
  },
  fwDELETE (endpoint, body = {}, service = null, servicePath = null, token = null) {
    let headers = {
      // 'Accept': 'application/json',
      'Content-Type': 'text/json',
      'fiware-service': service,
      'fiware-servicepath': servicePath
    }
    if (token) {
      headers['Authorization'] = 'JWT ' + token
    }
    return fetch(OrionURL + endpoint, { method: 'DELETE', headers, body: JSON.stringify(body) })
  }
}
