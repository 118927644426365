import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    tokenRPT: null,
    mepBackend: [],
    fiwareService: null,
    fiwareServiceList: [],
    userProfile: {}
  },
  getters: {
    tokenRPT: state => state.tokenRPT,
    mepBackend: state => state.mepBackend,
    fiwareService: state => state.fiwareService,
    fiwareServiceList: state => state.fiwareServiceList,
    userProfile: state => state.userProfile
  },
  mutations: {
    setTokenRPT (state, tokenRPT) {
      state.tokenRPT = tokenRPT
    },
    setMepBackend (state, mepBackend) {
      state.mepBackend = mepBackend
    },
    setFiwareService (state, fiwareService) {
      state.fiwareService = fiwareService
    },
    setFiwareServiceList (state, fiwareServiceList) {
      state.fiwareServiceList = fiwareServiceList
    },
    setUserProfile (state, userProfile) {
      state.userProfile = userProfile
    },
    clearState (state) {
      state = {
        tokenRPT: null,
        mepBackend: [],
        fiwareService: null,
        fiwareServiceList: [],
        userProfile: {}
      }
    }
  },
  actions: {
    setFiwareService (context, service) {
      context.commit('setFiwareService', service)
    },
    logout (context) {
      context.commit('clearState')
    }
  },
  modules: {
  }
})

// export default new Vuex.Store({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

export default store
