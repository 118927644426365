<template>
  <v-app>
    <core-app-bar @logout="handleLogout" @drawer="drawer = !drawer" />

    <core-view />
    <navigation-drawer v-model="drawer" />

    <core-footer />
  </v-app>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'App',
  props: ['keycloak'],
  components: {
    CoreAppBar: () => import('@/core/AppBar'),
    CoreFooter: () => import('@/core/Footer'),
    CoreView: () => import('@/core/View'),
    NavigationDrawer: () => import('@/core/NavigationDrawer')
  },
  methods: {
    handleLogout () {
      this.keycloak.logout()
    }
  },
  data: () => ({
    drawer: false
  }),
  destroyed () {
    // window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapGetters(['userProfile'])
  },
  created () {
    // console.log('create ____')
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  }
}
</script>
