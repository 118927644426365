import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import kcConf from '../keycloak_conf.json'
import Keycloak from 'keycloak-js'
import api from './services/api'

Vue.config.productionTip = false

const keycloak = Keycloak(kcConf)

keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).error(console.error)

keycloak.onAuthSuccess = function () {
  keycloak.loadUserProfile().error(console.error).success((profile) => {
    store.commit('setUserProfile', profile)
  })

  // getTokenRPT(keycloak.token)
  getServices(keycloak.token)
  store.commit('setTokenRPT', keycloak.token)
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App, { props: { keycloak: keycloak } })
    // render: h => h(App)
  }).$mount('#app')
}

keycloak.onTokenExpired = function () {
  keycloak.updateToken(55).error(console.error).success((refreshed) => {
    if (refreshed) {
      console.log('Token refreshed')
      store.commit('setTokenRPT', keycloak.token)
      // getTokenRPT(keycloak.token)
      getServices(keycloak.token)
    } else {
      console.log('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
    }
  })
}

function getServices (token) {
  api.get('/api/users/me', null, null, token).then((response) => {
    if (response.status === 200) {
      response.json().then((json) => {
        store.commit('setUserProfile', Object.assign({}, store.state.userProfile, json))
        store.commit('setMepBackend', json['roles'] || [])
        store.commit('setFiwareServiceList', json['services'].map((s) => s.replace('mep-service:', '')))
        if (json['services'].length > 0) {
          store.commit('setFiwareService', json['services'][0].replace('mep-service:', ''))
        }
      })
    }
  })
}

// setInterval(() => {
//   console.log('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
// }, 5000)

// function getTokenRPT (token) {
//   const urlencoded = new URLSearchParams({
//     'grant_type': 'urn:ietf:params:oauth:grant-type:uma-ticket',
//     'audience': 'mep-backend'
//   })

//   api.postRPT('https://idp.ino.nectec.or.th:8443/auth/realms/ino.nectec.or.th/protocol/openid-connect/token', urlencoded, token).then((response) => {
//     // console.log(response.status)
//     if (response.status === 200) {
//       response.json().then((json) => {
//         if (json.access_token) {
//           const accessToken = jwtdecode(json.access_token)
//           if (accessToken['resource_access']['mep-backend']) {
//             store.commit('setMepBackend', accessToken['resource_access']['mep-backend']['roles'] || [])
//           }
//           store.commit('setTokenRPT', json.access_token)
//           if (accessToken['authorization']['permissions']) {
//             let services = accessToken['authorization']['permissions'].filter((p) => p.rsname.startsWith('urn:mep-backend:resources:services:')).map((s) => s.rsname.split(':')[s.rsname.split(':').length - 1])
//             console.log(services)
//             store.commit('setFiwareService', services[0])
//             store.commit('setFiwareServiceList', services)
//           }
//         }
//       })
//     }
//   })
// }

// new Vue({
//   router,
//   store,
//   vuetify,
//   // render: h => h(App, { props: { keycloak: keycloak } })
//   render: h => h(App)
// }).$mount('#app')
