import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VJsoneditor from 'v-jsoneditor'
import colors from 'vuetify/lib/util/colors'
import ToastComponent from '@/components/Toast.vue'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const optionsToast = {
  position: 'bottom-right',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
  transition: 'Vue-Toastification__fade',
  maxToasts: 20,
  newestOnTop: true
}

Vue.use(Toast, optionsToast)

Vue.use(Vuetify)
Vue.use(VJsoneditor)

Vue.mixin({
  methods: {
    globalToastFn (title, desc) {
      return {
        component: ToastComponent,
        props: {
          title: title,
          description: desc
        }
      }
    }
  }
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#219ebc',
        secondary: '#00bcd4',
        error: '#d62828',
        accent: '#795548',
        danger: '#f21b3f',
        warning: '#ff9914',
        info: '#08bdbd',
        success: '#29bf12',
        header: '#A8A7A7',
        footer: '#372c2e',
        coreview: '#22223b',
        listactive: '#cdd6dd'
      },
      dark: {
        primary: colors.blue.lighten3
      }
    }
  }
})
